// src/components/Footer.js
import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <footer className="footer-newsletter-overlap bg-blue">
        <div className="container foot-container">
          <div className="pb-80 text-white">
            <div className="row justify-content-between">
              <div className="col-lg-3 col-md-6 col-sm-8  mt-5 mt-sm-3">
               
                <div className="row">
                  <div className="col-xl-5 col-6">
                    <ul className="list-unstyled">
                  
                    </ul>
                  </div>
                  <div className="col-xl-5 col-6">
                    <ul className="list-unstyled">
                     
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-8  mt-5 mt-sm-3">
                <h3 className="font-weight-bold mb-30">Our Pages</h3>
                <ul className="list-unstyled">
                  <li className="mb-2">
                    <Link to="/aboutus" className="text-light">
                      <i className="fas fa-chevron-right small mr-2"></i> About
                    </Link>
                  </li>
                  <li className="mb-2">
                    <Link to="/blog-list" className="text-light">
                      <i className="fas fa-chevron-right small mr-2"></i> Blog
                    </Link>
                  </li>
                  <li className="mb-2">
                    <Link to="/contactUs" className="text-light">
                      <i className="fas fa-chevron-right small mr-2"></i>{" "}
                      Contact
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-8  mt-5 mt-sm-3">
                <h3 className="font-weight-bold mb-30">Valuable Links</h3>
                <ul className="list-unstyled">
                 

                  <li className="mb-2">
                    <a href="#!" className="text-light">
                      <i className="fas fa-chevron-right small mr-2"></i>{" "}
                      Privacy Policy
                    </a>
                  </li>
                  <li className="mb-2">
                    <a href="#!" className="text-light">
                      <i className="fas fa-chevron-right small mr-2"></i> Terms
                      of Service
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-8  mt-5 mt-sm-3">
              
               
                <div className="mt-30">
                  <a href="#!" className="p-3 text-light">
                    <i className="fab fa-facebook-f"></i>
                  </a>
                  <a href="#!" className="p-3 text-light">
                    <i className="fab fa-twitter"></i>
                  </a>
                  <a href="#!" className="p-3 text-light">
                    <i className="fab fa-instagram"></i>
                  </a>
                  <a href="#!" className="p-3 text-light">
                    <i className="fab fa-linkedin-in"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom text-light py-3 border-dark border-top">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-12 text-center text-lg-left mb-3 mb-lg-0">
                &copy; Copyright All Review{" "}
                <span className="text-primary">Hapus </span> Technologies Pvt. Ltd.
              </div>
             
            </div>
          </div>
        </div>
      </footer>
    );
};

export default Footer;
