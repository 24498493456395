import React, { useState, useEffect } from "react";
import { Link, Navigate } from "react-router-dom";
import axios from "axios";
import {API} from "../config"
import ReactStars from "react-rating-stars-component";
// import DisplayRazorpay from "../payment/payment"; // Ensure this is the correct import

const CourseCard = ({
  course,
  showViewCourseButton = true,
  showBuyCourseButton = true,
  avgRating,
}) => {
  const [redirect, setRedirect] = useState(false);
  const [purchased, setPurchased] = useState(false);

  const userInfo = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null;
  const userId = userInfo?.userId;
  const token = userInfo?.token;

  useEffect(() => {
    if (userId) {
    //   checkUserPurchase();
    }
  }, [userId, course._id]);

//   const checkUserPurchase = async () => {
//     try {
//       const response = await axios.get(`${API}/courseUser/${userId}`);
//       const purchasedCourses = response.data;
//       const hasPurchased = purchasedCourses.some(c => c.course === course._id);
//       setPurchased(hasPurchased);
//     } catch (error) {
//       console.log("Error Checking Purchase Status: ", error.response);
//     }
//   };

//   const onClickBuyButton = () => {
//     DisplayRazorpay({ userId, courseID: course._id, price: course.price, institute: course.createdBy?._id });
//   };

  const ratingAvg = avgRating.find(data => data._id === course._id)?.averageRating || 0;

  if (redirect) {
    return <Navigate to="/cart" />;
  }

  return (
    <div>
 <div className="card course-card shadow mt-40">
  <Link className="text-decoration-none">
 <img className="card-img-top img-fluid" src={course.photoUrl} alt={course.title} />
 </Link>
              <div className="card-body p-30">
                <h5 className="font-weight-600"><a href="#!" className="text-blue">Machine Learning A-Z: hands-On Python In Data Science</a></h5>
                <p className="mt-2">She exposed painted fifteen are noisier mistake led waiting surprise.</p>
              </div>
              <div className="px-30">
                <div className="card-footer px-0 bg-transparent mb-10 d-flex justify-content-between align-items-center">
                  <div className="rating text-primary">
                    <span className="font-weight-600">4.3</span>
                    <i className="fas fa-star"></i>
                    <i className="fas fa-star"></i>
                    <i className="fas fa-star"></i>
                    <i className="fas fa-star"></i>
                  </div>
                  <p className="price h6">
                  ₹1200.99 <s>₹1300.99</s>
                  </p>
                </div>
              </div>
            </div>


  
    <div className="card course-card shadow mt-10">
   <Link to={`/coursedetails/${course._id}`} >
      <img className="card-img-top img-fluid" src={course.photoUrl} alt={course.title} />
      </Link>
      {!purchased && showBuyCourseButton && (
        <button
          className="btn btn-outline-danger m-1 bg-color-red "
        //   onClick={onClickBuyButton}
        >
          Buy <i className="bi bi-currency-rupee">₹</i> {course.price}
        </button>
      )}

      <div className="d-flex justify-content-center">
        <ReactStars
          name="rating"
          count={5}
          value={ratingAvg}
          size={24}
          edit={false}
          activeColor="#ffd700"
          isHalf={true}
        />
      </div>

      <div className="card-body p-20 pt-0 height-110px">
        <h5 className="font-weight-100">
          <Link to={`/coursedetails/${course._id}`} className="text-blue text-decoration-none one-line-restrict-text">
            {course.title}
          </Link>
        </h5>
        <p className="text-muted mb-2 two-line-restrict-text">
          {course.shortDescription}
        </p>
      </div>
    </div>

    </div>
  );
};

export default CourseCard;
