import React from 'react';
import { Link } from 'react-router-dom';
// This should be the path to your CSS file

const AdminDashboard = () => {
  const adminLinks = [
    { path: '/admin-dashboard', label: 'Dashboard' },
    { path: '/admin-category', label: 'Categories' },
    { path: '/admin-products', label: 'Products' },
    { path: '/admin-orders', label: 'Orders' },
    { path: '/admin-users', label: 'Users' },
    { path: '/admin-settings', label: 'Settings' }
  ];

  return (
    <div className="admin-dashboard">
      <h1 className="center">Admin Dashboard</h1>
      <div className="card-table">
        {adminLinks.map(link => (
          <div key={link.path} className="card">
            <Link to={link.path} className="card-link">
              {link.label}
            </Link>
          </div>
        ))}
      </div>
      {/* Admin-specific content */}
    </div>
  );
};

export default AdminDashboard;
