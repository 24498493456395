import axios from 'axios';
import {API} from "../config"
 

const API_URL = API; // Replace with your actual API URL

export const createCategory = async (userId, token, name) => {
    if (!userId) {
      throw new Error("User ID is undefined Category Create");
    }
    try {
      const response = await axios.post(`${API_URL}/category/create/${userId}`,  name , {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  };

export const getCategories = async () => {
  try {
    const response = await axios.get(`${API_URL}/categories`);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};


export const updateCategory = async (categoryId, userId, token, categoryName) => {
  // Prepare the request payload
  const payload = typeof categoryName === 'object' && categoryName.name ? { name: categoryName.name } : { name: categoryName };

  try {
    const response = await axios.put(`${API_URL}/category/${categoryId}/${userId}`, payload, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    // Ensure we are handling errors where the response might not be available
    if (error.response && error.response.data) {
      throw error.response.data;
    } else {
      throw new Error('An unexpected error occurred');
    }
  }
};




export const deleteCategory = async (categoryId, userId, token) => {
  try {
      const response = await axios.delete(`${API_URL}/category/${categoryId}/${userId}`, {
          headers: {
              Authorization: `Bearer ${token}`
          }
      });
      return response.data;
  } catch (error) {
      throw error.response.data;
  }
};
