import React, {useState,useEffect} from 'react';
import {Link} from "react-router-dom"
import {Col, Container, Row, Card, ListGroup} from "react-bootstrap";
import Layout from "../components/Layout"
import axios from 'axios';

const StudentDashboard = () => {


    const [courseInfo, setCourseInfo] = useState([]);
    
  
  
  
  // Retrieve user information from localStorage
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const { userId, token } = userInfo || {}; // Destructure userId and token

 
  
    const StudentLinks = () => {
      return (
        <Card>
        <Card.Header className="bg-primary">
          <h3>User Links</h3>
        </Card.Header>
        <ListGroup variant="flush">
          <ListGroup.Item>
          <i className="bi bi-cart-check-fill"></i>   <Card.Link href="/cart">My Purchases</Card.Link>
          </ListGroup.Item>
          <ListGroup.Item>
          <i className="bi bi-pencil-square"></i>   <Link to={`/profile-update/${userId}`}>Update Profile</Link>
          </ListGroup.Item>
        </ListGroup>
      </Card>
      );
    };
  
    const StudentInfo = () => {
        return (
          <Card>
            <Card.Header className="bg-info">
              <h3>User Information</h3>
            </Card.Header>
            <ListGroup variant="flush">
              <ListGroup.Item><i className="bi bi-person-circle"></i> UserName: {userInfo.name}</ListGroup.Item>
              <ListGroup.Item><i className="bi bi-envelope"></i> Email Id : {userInfo.email}</ListGroup.Item>
             
            </ListGroup>
          </Card>
        );
      };
    

  
    const coursesInfo = () => {
        return (
            <table className="table table-striped table-hover">
                <thead>
                    <tr className="bg-head">
                        <th scope="col"></th>
                        <th scope="col">Manage Course</th>
                    </tr>
                </thead>
                <tbody>
                    {courseInfo.length ? (
                        courseInfo.map((c, i) => (
                            <tr key={i}>
                                <td>{i + 1}</td>
                                <td>
                                    <Link className="nav-link theme-color-blue" to={`/update-course/${c._id}`}>
                                        {c.title}
                                        <i className="fas fa-edit f-right"></i>
                                    </Link>
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="2">No courses available.</td>
                        </tr>
                    )}
                </tbody>
            </table>
        );
    };



    return (
        <Layout
        title="Dashboard"
        description={`Hello, ${userInfo.name}!`}
        parentComp="instDash"
        className="container-fluid"
      >
        <Container>
          <Row>
            {/* <Col className="mb-2"  sm={4}> {instLinks()}</Col> */}
            <div className="col-lg-4 col-md-6 col-sm-12">{StudentLinks()}</div>
  
            <Col sm={6}>
              {StudentInfo()}
              {/* {coursesInfo()} */}
            </Col>
          </Row>
        </Container>
      </Layout>
    );
};

export default StudentDashboard;
