import React, { useState, useEffect } from "react";
import Layout from "../components/Layout";
import { createCourse , getCategories } from "./InstituteApi"
import { Container, Form, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";



const CreateCourse = () => {


      // Retrieve user information and token from localStorage
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const { userId, token } = userInfo || {};


 // Initialize component state
 const [values, setValues] = useState({
    photoUrl: "",
    title: "",
    description: "",
    shortDescription: "",
    price: "",
    category: "",
    loading: false,
    error: "",
    createdCourse: "",
    redirectToCourse: false,
    formData: new FormData(),
  });

  const navigate = useNavigate()

  const [submitOn, setSubmitOn] = useState(true);

// Destructure values
const {
    title,
    description,
    shortDescription,
    categories,
    category,
    price,
    loading,
    error,
    createdCourse,
    formData,
  } = values;


  //load categories and  set form data

  const init = () => {
    getCategories().then((data) => {
      if (data.error) {
        setValues({ ...values, error: data.error });
      } else {
        setValues({ ...values, categories: data,  formData: new FormData() });
      }
    });
  };

  useEffect(() => {
    init();
  }, []);

  // higher order function (=> =>) , function in function
  const handleChange = (name) => (event) => {
    let value = event.target.value;
    if (name === "image") {
      const file = event.target.files[0];
      if (file.size > 2000000) {
        setValues({ ...values, error: "Image size must be smaller than 2 MB" });
        setSubmitOn(false);
        return;
      }
      if (!file.name.match(/\.(jpg|png)$/)) {
        setValues({ ...values, error: "Image type should be JPG or PNG" });
        setSubmitOn(false);
        return;
      }
      value = file;
    }
    if (!submitOn) {
      setSubmitOn(true);
    }
    formData.set(name, value);
    setValues({ ...values, [name]: value });
  };



  // BUg report : data submission twice, data remained in state(fields)
  const clickSubmit = (event) => {
    event.preventDefault();
    setValues({ ...values, error: "", loading: true });

    createCourse(userId, token, formData).then((data) => {
      if (data.error) {
        setValues({ ...values, error: data.error });
      } else {
        navigate('/institute-dashboard')
        setValues({
          ...values,
          photoUrl: "",
          description: "",
          title: "",
          price: "",
          loading: false,
           createdCourse: data.name,
        });
        setSubmitOn(false)
      }
    });
  };

  const newPostForm = () => (

<Container>
    <Form action="/uploadsingle" className="mb-3" onSubmit={submitOn === true ? clickSubmit : null}>  

  <Form.Group className="mb-3">
    <Form.Label>Title</Form.Label>
    <Form.Control type="text" value={title} onChange={handleChange("title")} maxLength="120" placeholder="title of course" required />
  </Form.Group>

  <Form.Group className="mb-3">
    <Form.Label>Short Description</Form.Label>
    <Form.Control type="text" value={shortDescription} onChange={handleChange("shortDescription")} maxLength="40" placeholder="short description" required />
  </Form.Group>

  <Form.Group className="mb-3">
    <Form.Label>Description</Form.Label>
    <Form.Control as="textarea" rows={3} value={description} maxLength="1000" onChange={handleChange("description")} placeholder="some description about course.." required/>
  </Form.Group>

  <Form.Group className="mb-3">
    <Form.Label>Category</Form.Label>
    {/* <Form.Select > */}
    <select onChange={handleChange("category")} className="form-control" required>
           <option>Select Category</option>

       {categories &&
             categories.map((c, i) => (
               <option key={i} value={c._id}>
                 {c.name}
               </option>
             ))}
         </select>
 
{/* </Form.Select>  */}</Form.Group>

 <Form.Group className="mb-3">
        <Form.Label>Price (&#x20b9;)</Form.Label>
        <Form.Control
          type="number"
          onChange={handleChange("price")}
          value={price}
          min="100"
          max="99999"
          required
        />
      </Form.Group>

  <Form.Group className="my-4" controlId="formFile" >
      <Form.Label className="file-button" > 
      <Form.Control type="file" name="image" accept="image/png, image/jpeg, image/jpg" onChange={handleChange("image")} required />
      </Form.Label>
      
    </Form.Group>

    <Button className="my-4" variant="outline-primary" type="submit"> Submit </Button>

    

  </Form>
</Container>



  );

  const showSuccess = () => (
        <div className={createdCourse && createdCourse ? "alert alert-info" : "none"}>
     { createdCourse &&  <h2> {`${createdCourse}`} is created. Click to add lectures</h2>}
     {/* {`${createdCourse}`} */}
    </div>
  );

  const showError = () => (
    <div className={ error && error ? "alert alert-danger" : "none"} >{error}</div>
   
  );

  const showLoading = () =>
    loading && (
      <div className="alert alert-success">
        <h2>UpLoading...</h2>
      </div>
    );

  return (
    <Layout title="Create New Course" description={`${userInfo.name}`}>
      <div className="row">
        <div className="col-md-8 offset-md-2">
          {showError()}
          {showSuccess()}
          {showLoading()}
          {newPostForm()}
        </div>
      </div>
    </Layout>
  );
};

export default CreateCourse;
