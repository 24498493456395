import axios from "axios";
import { API } from "../config";

export const createCourse = (userId, token, course) => {
    //  console.log(name, email, password)
    return fetch(`${API}/${userId}/course/create/`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: course,
    })
      .then((response) => {
        return response.json();
      })
      .catch((err) => {
        console.log(err);
     
      });
  };


  export const getCourse = (courseId) => {
    return fetch(`${API}/course/${courseId}`, {
      method: "GET",
      headers : { 
        'Content-Type': 'application/json',
        'Accept': 'application/json'
       }
    })
      .then((response) => {
        return response.json();
      })
      .catch((err) => console.log(err));
  };
  
  

  export const getCoursesByInstitute = (userId, token) => {
    return fetch(`${API}/courses/by/${userId}` , {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
     
    })
      .then((response) => {
        return response.json();
      })
      .catch((err) => {
        console.log(err);
    })
  }

// export const patchCourse = (courseId, userId, token, course) => {
//   return fetch(`${API}/course/${courseId}/${userId}`, {
//     method : "PATCH",
//     headers: {
//       Accept : "application/json",
//       "Content-Type" : "application/json",
//       Authorization : `Bearer ${token}`,
//     },
//     body : course,
//   })
//   .then(response => {
//     return response.json();
//   })
//   .catch(err => {
//     console.log(err)
//   } )
// }
 
export const patchCourse = ( courseId, userId, token, courseData) => {
  //  console.log(name, email, password)
  return fetch(`${API}/course/${courseId}/${userId}`, {
    method: "PATCH",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  //  body: JSON.stringify({userId, courseId, video}),
  body : courseData,
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};

export const uploadVideo = async (userId, token, formData, courseId) => {
    console.log("upload Video API userId:",userId)
    console.log("upload Video API courseId:", courseId)
    try {
      const response = await axios.post(`${API}/video/create/${courseId}`, formData, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
          // Optional: If you want to explicitly send userId
          'User-Id': userId 
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error uploading video:', error);
      return { error: error.response.data };
    }
};


export const signupInst = async (data) => {
  return await axios.post(`${API}/signUpInst`,data)
};

export const instituteSignup = async (userData) => {
  try {
    const response = await axios.post(`${API}/institute-signup`, userData);
    return response.data;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.error("Error data:", error.response.data);
      console.error("Error status:", error.response.status);
      console.error("Error headers:", error.response.headers);
      return error.response.data;
    } else if (error.request) {
      // The request was made but no response was received
      console.error("Error request:", error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error('Error', error.message);
    }
    console.error("Error config:", error.config);
    return { error: "An unknown error occurred during the request." };
  }
};

export const getInst = async (id) => {
  return await axios.get(`${API}/getInst/${id}`)
};

export const updateInst = async (id,data) => {
  return await axios.patch(`${API}/updateInst/${id}`,data)
};

export const getReview = async (id) => {
  return await axios.get(`${API}/getReview/${id}`)
};
export const getStats = async (userId, token) => {
    try {
        const response = await axios.get(`${API}/getStats/${userId}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching stats:', error.response);
        throw error.response.data;
    }
};

export const getCategories = async () => {
    try {
      const response = await axios.get(`${API}/categories`);
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  };

export const createQuiz = async (data) => {
  return await axios.post(`${API}/createQuiz`,data)
};

export const getQuizList = async (id) => {
  return await axios.get(`${API}/getQuizList/${id}`)
};

export const getQuiz = async (id) => {
  return await axios.get(`${API}/getQuiz/${id}`)
};

export const updateQuiz = async (id,data) => {
  return await axios.patch(`${API}/updateQuiz/${id}`,data)
};

export const deleteQuiz = async (id) => {
  return await axios.patch(`${API}/deleteQuiz/${id}`)
};

export const createBlog = async (data) => {
  return await axios.post(`${API}/createBlog`,data)
};

export const blogList = async (id) => {
  return await axios.get(`${API}/getBlogList/${id}`)
};

export const blogById = async (id) => {
  return await axios.get(`${API}/getBlog/${id}`)
};

export const updateBlog = async (id,data) => {
  return await axios.patch(`${API}/updateBlog/${id}`,data)
};

export const deleteBlog = async (id) => {
  return await axios.delete(`${API}/deleteBlog/${id}`)
};

export const urlGenerate = async (data) => {
  return await axios.post(`${API}/imageUrlGenerate`,data)
};
