import React, { useState, useEffect } from 'react';
import axios from 'axios';
import CourseCard from './CourseCard'; // Update the path as needed
import { API } from '../config'; // Update the path as needed
import Preloader from './Preloader';

const HomePage = () => {
    const [courses, setCourses] = useState([]);
    const [avgRating, setAvgRating] = useState([]);
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchCourses = async () => {
            try {
                const response = await axios.get(`${API}/courses`);
                if (response.data && response.data.courses) {
                    // Add a publishedDate field to your course objects if it's available
                    const coursesWithDate = response.data.courses.map((course) => ({
                        ...course,
                        publishedDate: new Date(course.createdAt), // Convert to Date object
                    }));

                    // Sort courses by publishedDate in descending order (recent first)
                    coursesWithDate.sort((a, b) => b.publishedDate - a.publishedDate);

                    setCourses(coursesWithDate);
                    setAvgRating(response.data.avgRating);
                    setIsLoading(false);
                } else {
                    setError('Invalid data format received from API');
                }
            } catch (err) {
                setError('Failed to fetch courses');
                console.error('Error fetching courses:', err);
            }
        };
        fetchCourses();
    }, []);

    return (
      <Preloader loading={isLoading}>
          <section className="section-padding">
            <div className="container">
                <div className="row justify-content-start">
                
         <div class="col-12 text-center mb-30">
            <h2 class="section-title">Largest Selection Of Courses</h2>
         </div>
                    {error && <div className="alert alert-danger">{error}</div>}

                    {courses.map((course, index) => (
                        <div className="col-lg-4 col-md-5 col-sm-6 p-2" key={index}>
                            <CourseCard
                                course={course}
                                showViewCourseButton={true}
                                showBuyCourseButton={true}
                                avgRating={avgRating}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </section>
      </Preloader>
    );
};

export default HomePage;
