import React from 'react';
import { Spinner } from 'react-bootstrap';

const Preloader = ({ children, loading }) => {
    if (loading) {
        return (
            <div className="preloader">
                
                    <span className="sr-only">Loading...</span>
                
            </div>
        );
    }

    return <>{children}</>;
};

export default Preloader;