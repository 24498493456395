// src/components/Header.js
import React, { useContext, useState, Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";
import { UserContext } from "../context/UserContext";
import { FaSignInAlt, FaSignOutAlt, FaUserPlus, FaBars, FaHome } from 'react-icons/fa';

const Header = () => {
  const { user, logout } = useContext(UserContext);
  const navigate = useNavigate();
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);

  const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);
  const handleLogout = () => {
    logout();
    navigate("/");
  };

  return (
    <>
          <header className="bg-blue shadow">
      <div className="container-lg">
        <nav className="navbar navbar-expand-xl navbar-dark px-0">
          <Link className="navbar-brand" to="/">
          
  <img
    src={`./hapus-logo-bg.ico`}
    alt="Hapus Logo"
    className="logo"
  />
  <h3 className="text-md text-white logo-text">Hapus</h3>


            
          </Link>

          <button className="navbar-toggler" type="button" onClick={handleNavCollapse}>
            <FaBars />
          </button>

          <div className={`${isNavCollapsed ? 'collapse' : ''} navbar-collapse`} id="navbar">
          <ul className="navbar-nav mr-auto">
              {/* Common Links */}
              <li className="nav-item">
                <Link className="nav-link text-white" to="/"><FaHome /> Home </Link>
              </li>

                  {user && user.userType === "student" && (
                    <li className="nav-item">
                      <Link className="nav-link" to="/student-dashboard">
                        Dashboard
                      </Link>
                    </li>
                  )}

                  {user && user.userType === "institute" && (
                    <li className="nav-item">
                      <Link className="nav-link" to="/institute-dashboard">
                        Dashboard
                      </Link>
                    </li>
                  )}

                  {user && user.userType === "admin" && (
                    <li className="nav-item">
                      <Link className="nav-link" to="/admin-dashboard">
                        Dashboard
                      </Link>
                    </li>
                  )}

                  {/* <li className="nav-item">
                    <Link className="nav-link" to="/courses">
                      Courses
                    </Link>
                  </li> */}
                  <li className="nav-item">
                    <Link className="nav-link" to="/blog-list">
                      Blog
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/aboutus">
                      About Us
                    </Link>
                  </li>

                  <li className="nav-item">
                    <Link className="nav-link" to="/contactUs">
                      Contact Us
                    </Link>
                  </li>

                 {/* Authentication Links */}
              {!user ? (
                <>
                  <li className="nav-item">
                    <Link className="nav-link" to="/login">Signin <FaSignInAlt /></Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/student-signup">Signup <FaUserPlus /></Link>
                  </li>
                </>
              ) : (
                <li className="nav-item">
                  <Link className="nav-link" to="/" onClick={handleLogout}>Signout <FaSignOutAlt /></Link>
                </li>
              )}
            </ul>
            <div className="ml-auto">
              <Link to={"/institute-signup"} className="btn btn-sm btn-primary rounded-pill m-2">
                Signup As Institute <FaUserPlus />
              </Link>
            </div>
          </div>
        </nav>
      </div>
    </header>
    </>
  );
};

export default Header;
