import React from "react";

const ContactUs = () => {
  return (
    <section className="section-padding bg-gray">
      <div className="container">
        <div className="row justify-content-between">
          <div className="col-lg-7 order-1 order-lg-0">
            <div className="mb-5">
              <h2 className="text-secondary font-weight-bold mb-2">
                Send a Message
              </h2>
              <p>Your email address will not be published. </p>
            </div>
            <form action="#">
              <div className="row">
                <div className="col-md-6">
                  <div className="mb-30">
                    <label htmlFor="name">Name*</label>
                    <input
                      type="text"
                      className="form-control rounded-sm"
                      id="name"
                      placeholder="John Doe"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-30">
                    <label htmlFor="email">Email*</label>
                    <input
                      type="email"
                      className="form-control rounded-sm"
                      id="email"
                      placeholder="jack@email.com"
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="mb-30">
                    <label htmlFor="sub">Subject</label>
                    <input
                      type="text"
                      className="form-control rounded-sm"
                      id="sub"
                      placeholder="I want to know about course."
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="mb-30">
                    <label htmlFor="message">Message</label>
                    <textarea
                      className="form-control rounded-sm"
                      id="message"
                      rows="5"
                    ></textarea>
                  </div>
                </div>
                <div className="col-md-12">
                  <button type="submit" className="btn btn-primary rounded-sm">
                    Send Message
                  </button>
                </div>
              </div>
            </form>
          </div>

          <div className="col-xl-4 col-lg-5 mb-5 mb-lg-0 order-0 order-lg-1">
            <div className="mb-5">
              <h2 className="text-secondary font-weight-bold mb-2">
                Contact Info
              </h2>
              <p>Welcome to Hapus. </p>
            </div>
            <div className="shadow-sm p-20 mt-4 rounded-sm bg-white d-block d-sm-flex align-items-center">
              <i className="fas fa-phone fa-2x text-primary"></i>
              <div className="ml-sm-4 mt-3 mt-sm-0">
                <h4 className="text-secondary font-weight-600 mb-1">
                  Contact Details
                </h4>
                {/* <p>Phone: <a href="tel:+7800123452" className="text-dark"></a></p> */}
                <p>Mail: <a href="mailto:admin@hapus.app" className="text-dark">admin@hapus.app</a></p>
              </div>
            </div>
            <div className="shadow-sm p-20 mt-4 rounded-sm bg-white d-block d-sm-flex align-items-center">
              <i className="fas fa-map-marked-alt fa-2x text-primary"></i>
              <div className="ml-sm-4 mt-3 mt-sm-0">
                <h4 className="text-secondary font-weight-600 mb-1">
                  Location
                </h4>
                <p>First Floor, BHAU Institute, COEP Campus, Near Boat CLub, Shivajinagar, Pune - 411005</p>
              </div>
            </div>
            <div className="shadow-sm p-20 mt-4 rounded-sm bg-white d-block d-sm-flex align-items-center">
              <i className="fas fa-user-clock fa-2x text-primary"></i>
              <div className="ml-sm-4 mt-3 mt-sm-0">
                <h4 className="text-secondary font-weight-600 mb-1">
                  Opening Hours
                </h4>
                <p>Monday-Friday</p>
                <p>10:30a.m-7:00p.m</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactUs;
