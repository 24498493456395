import React, { useState, useContext } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { UserContext } from '../context/UserContext';

const SharedLogin = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [role, setRole] = useState('student');
  const { login, authError } = useContext(UserContext);

  const handleChange = (field) => (e) => {
    if (field === 'email') setEmail(e.target.value);
    if (field === 'password') setPassword(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    login({ email, password }, role).catch((error) => {
      toast.error(error.message || 'Login failed', {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 5000,
      });
    });
  }

  return (
    <section className="section-padding bg-gray">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-6">
            <div className="login-content">
              <h4 className="modal-title text-secondary font-weight-600">
                Welcome back
              </h4>
              <ul className="nav nav-pills nav-justified tab-nav" id="myTab">
                <li className="nav-item" onClick={() => setRole("student")}>
                  <button
                    className={`nav-link ${role === "student" ? "active" : ""}`}
                  >
                    {/* Replace with icon */}
                    Login as Student
                  </button>
                </li>
                <li className="nav-item" onClick={() => setRole("institute")}>
                  <button
                    className={`nav-link ${role === "institute" ? "active" : ""}`}
                  >
                    {/* Replace with icon */}
                    Login as Institute
                  </button>
                </li>
              </ul>
              <div className="tab-content" id="myTabContent">
                <div
                  className={`tab-pane fade show ${role === "student" ? "active" : ""}`}
                >
                  {/* Student Login Form */}
                  {renderLoginForm("student", email, password, handleChange, handleSubmit)}
                </div>
                <div
                  className={`tab-pane fade ${role === "institute" ? "show active" : ""}`}
                >
                  {/* Institute Login Form */}
                  {renderLoginForm("institute", email, password, handleChange, handleSubmit)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const renderLoginForm = (role, email, password, handleChange, handleSubmit) => (
  <form className="row" onSubmit={handleSubmit}>
    <div className="form-group mb-20 col-12">
      <label className="text-secondary h6 font-weight-600 mb-2">Email Address*</label>
      <input
        className="form-control shadow-none rounded-sm"
        type="email"
        value={email}
        onChange={handleChange("email")}
        required
      />
    </div>
    <div className="form-group mb-20 col-12">
      <label className="text-secondary h6 font-weight-600 mb-2">Password*</label>
      <input
        className="form-control shadow-none rounded-sm"
        type="password"
        value={password}
        onChange={handleChange("password")}
        required
      />
    </div>
    <div className="form-group col-12">
      <button className="btn btn-primary w-100 rounded-sm" type="submit">
        Sign In as {role.charAt(0).toUpperCase() + role.slice(1)}
      </button>
    </div>
  </form>
);

export default SharedLogin;
