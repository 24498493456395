import React,{useState} from 'react'
import { MultiSelect } from "react-multi-select-component";
import {instituteSignup} from "../InstituteApi"

const SignupForm = () => {


     // Define state for each form field
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    website: "",
    address: "",
    city: "",
    state: "",
    instituteHead: "",
    dateOfEstablishment: "",
    courseLanguageOffered: [],
    acceptTerms: false,
  });

// Options for the MultiSelect component
const languageOptions = [
    { label: "English", value: "english" },
    { label: "hindi", value: "hindi" },
    { label: "marathi", value: "marathi" },
    // ... other language options
  ];


  const handleMultiSelectChange = (selectedOptions) => {
    setFormData({
      ...formData,
      courseLanguageOffered: selectedOptions,
    });
  };
  

  // Handle form field changes
  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value
    });
  };

 // Handle form submission
 const handleSubmit = async (event) => {
    event.preventDefault();
    
    // Check if passwords match
    if (formData.password !== formData.confirmPassword) {
      alert("Passwords do not match!");
      return;
    }
  
    // Check if terms and conditions are accepted
    if (!formData.acceptTerms) {
      alert("You must accept the terms and conditions!");
      return;
    }
  
    // Prepare data for the API request
    const userData = {
      name: formData.name,
      email: formData.email,
      mobile: formData.mobile,
      website: formData.website,
      address: formData.address,
      city: formData.city,
      state: formData.state,
      instituteHead: formData.instituteHead,
      dateOfEstablishment: formData.dateOfEstablishment,
      courseLanguageOffered: formData.courseLanguageOffered.map(lang => lang.value),
      password: formData.password,
    };
  
    // Make the API request
    const response = await instituteSignup(userData);
    if (response.error) {
      // Handle error response from the API
      console.error("Signup error:", response.error);
      alert("Signup failed: " + response.error);
    } else {
      // Handle successful response from the API
      console.log("Signup successful:", response);
      // Redirect or update UI after successful signup
    }
  };
  


  return (
    <div className="layout-wrapper" style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
    <form onSubmit={handleSubmit} className="mb-3">
      {/* Name */}
      <div className="form-group mb-20">
        <label className="text-secondary h6 font-weight-600 mb-2">Name</label>
        <input
          name="name"
          type="text"
          maxLength="64"
          className="form-control shadow-none rounded-sm"
          value={formData.name}
          onChange={handleChange}
          required
        />
      </div>

      {/* Email */}
      <div className="form-group mb-20">
        <label className="text-secondary h6 font-weight-600 mb-2">Email</label>
        <input
          name="email"
          type="email"
          maxLength="254"
          className="form-control shadow-none rounded-sm"
          value={formData.email}
          onChange={handleChange}
          required
        />
      </div>

      {/* Mobile and Website */}
      <div className="d-flex flex-row flex-wrap col-md-12">
        <div className="form-group mb-20 col-12 col-md-6 pe-md-2">
          <label className="text-secondary h6 font-weight-600 mb-2">Mobile</label>
          <input
            name="mobile"
            type="number"
            className="form-control shadow-none rounded-sm"
            value={formData.mobile}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group mb-20 col-12 col-md-6 ps-md-2">
          <label className="text-secondary h6 font-weight-600 mb-2">Website</label>
          <input
            name="website"
            type="url"
            maxLength="254"
            className="form-control shadow-none rounded-sm"
            value={formData.website}
            onChange={handleChange}
            required
          />
        </div>
      </div>

      {/* Password and Confirm Password */}
      <div className="d-flex flex-row flex-wrap col-md-12">
        <div className="form-group mb-20 col-12 col-md-6 pe-md-2">
          <label className="text-secondary h6 font-weight-600 mb-2">Password</label>
          <input
            name="password"
            type="password"
            className="form-control shadow-none rounded-sm"
            value={formData.password}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group mb-20 col-12 col-md-6 ps-md-2">
          <label className="text-secondary h6 font-weight-600 mb-2">Confirm Password</label>
          <input
            name="confirmPassword"
            type="password"
            className="form-control shadow-none rounded-sm"
            value={formData.confirmPassword}
            onChange={handleChange}
            required
          />
        </div>
      </div>

      {/* Address */}
      <div className="form-group mb-20">
        <label className="text-secondary h6 font-weight-600 mb-2">Address</label>
        <input
          name="address"
          type="text"
          maxLength="254"
          className="form-control shadow-none rounded-sm"
          value={formData.address}
          onChange={handleChange}
          required
        />
      </div>

      {/* City and State */}
      <div className="d-flex flex-row flex-wrap col-md-12">
        <div className="form-group mb-20 col-12 col-md-6 pe-md-2">
          <label className="text-secondary h6 font-weight-600 mb-2">City</label>
          <input
            name="city"
            type="text"
            maxLength="64"
            className="form-control shadow-none rounded-sm"
            value={formData.city}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group mb-20 col-12 col-md-6 ps-md-2">
          <label className="text-secondary h6 font-weight-600 mb-2">State</label>
          <input
            name="state"
            type="text"
            maxLength="64"
            className="form-control shadow-none rounded-sm"
            value={formData.state}
            onChange={handleChange}
            required
          />
        </div>
      </div>

      {/* Institute Head and Date of Establishment */}
      <div className="d-flex flex-row flex-wrap col-md-12">
        <div className="form-group mb-20 col-12 col-md-6 pe-md-2">
          <label className="text-secondary h6 font-weight-600 mb-2">Institute Head</label>
          <input
            name="instituteHead"
            type="text"
            maxLength="64"
            className="form-control shadow-none rounded-sm"
            value={formData.instituteHead}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group mb-20 col-12 col-md-6 ps-md-2">
          <label className="text-secondary h6 font-weight-600 mb-2">Date of Establishment</label>
          <input
            name="dateOfEstablishment"
            type="date"
            className="form-control shadow-none rounded-sm"
            value={formData.dateOfEstablishment}
            onChange={handleChange}
            required
          />
        </div>
      </div>

      {/* Course Language Offered */}
      <div className="form-group mb-20 col-md-6 z-index-1024">
        <label className="text-secondary h6 font-weight-600 mb-2">Course Language Offered</label>
        <MultiSelect
  options={languageOptions}
  value={formData.courseLanguageOffered}
  onChange={handleMultiSelectChange}
  labelledBy="Select"
  className="multiSelectDropdown"
  required
/>

      </div>

      {/* Terms and Conditions Checkbox */}
      <div className="form-group mb-20 mt-5 col-12">
        <input
          name="acceptTerms"
          type="checkbox"
          checked={formData.acceptTerms}
          onChange={handleChange}
          required
        />
        <p className="p-tag-inline ml-1">I accept <a href="/user/tnc" target="_blank">Terms and Conditions</a>.</p>
      </div>

      {/* Submit Button */}
      <button type="submit" className="btn btn-outline-primary">SignUp</button>
    </form>

    </div>
  );
};

export default SignupForm;