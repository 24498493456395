// src/components/AdminLogin.js
import React, { useState, useContext } from 'react';
import { UserContext } from '../context/UserContext';

const AdminLogin = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const { login, authError } = useContext(UserContext);

    const handleSubmit = (e) => {
        e.preventDefault();
        login({ email, password }, 'admin');
    };

    return (
        <div>


<div className="login-page">
      <div className="login-modal">
        <h2>Login</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              id="password"
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <button type="submit" className="login-btn">Log In</button>
        </form>
      </div>
    </div>
           
            
            {authError && <p>Error: {authError}</p>}
        </div>
    );
};

export default AdminLogin;
