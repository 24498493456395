// src/services/apiService.js
import axios from 'axios';
import {API} from "../config"

const API_BASE_URL = API; // Replace with your actual backend URL

const apiService = {

  
        loginInstitute: async (credentials) => {
            return axios.post(`${API_BASE_URL}/loginInst`, credentials);
        },
        loginUser: async (credentials) => {
            return axios.post(`${API_BASE_URL}/login`, credentials);
        },
        loginAdmin: async (credentials) => {
            return axios.post(`${API_BASE_URL}/admin/login`, credentials);
        },
        // ... other services
   
    // Example: Get courses
    getCourses: async () => {
        try {
            const response = await axios.get(`${API_BASE_URL}/courses`);
            return response.data;
        } catch (error) {
            // Handle error
            console.error('Error fetching courses:', error);
        }
    },
    // Add other methods for different endpoints
};

export default apiService;
