import React from 'react';
import SignupForm from './SignupForm'; // Ensure this path is correct
import Layout from '../../components/Layout'; // Ensure this path is correct

const InstituteSignup = () => {
  return (
    <Layout
      title="Institute Signup" // Add a descriptive title
      description="Register your institute and start offering courses" // Add a meaningful description
      className="container col-md-12 col-lg-8"
 
    >
      
      <SignupForm  />
          </Layout>
  );
};

export default InstituteSignup;
