import React from "react";

const AboutUs = () => {
  return (
    <div>


<section className="page-header">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <h2 className="section-title text-primary font-weight-bold mb-10">
                About Us
              </h2>
              <nav aria-label="breadcrumb"></nav>
            </div>
          </div>
        </div>
      </section>

      <div className="container about-us">
        <div className="row">
          <div className="col-md-6 mb-4">
            <div className="card h-100">
              <img
                src="https://via.placeholder.com/300x200"
                className="card-img-top"
                alt="Placeholder image"
              />
              <div className="card-body">
                <h2 className="card-title">Who We Are</h2>
                <p className="card-text">
                  Hapus Technologies Pvt Ltd is an innovative e-learning
                  platform dedicated to empowering individuals through
                  accessible and engaging education. We are a team of passionate
                  educators and technologists united by a common vision: to
                  revolutionize learning by making it a lifelong, enjoyable
                  journey for everyone, everywhere.
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-6 mb-4">
            <div className="card h-100">
              <img
                src="https://via.placeholder.com/300x200"
                className="card-img-top"
                alt="Placeholder image"
              />
              <div className="card-body">
                <h2 className="card-title">What We Do</h2>
                <p className="card-text">
                  We provide a dynamic e-learning marketplace platform that
                  seamlessly connects institutes with a vast community of eager
                  learners. Our platform is meticulously crafted to facilitate
                  institutes in showcasing their courses, while empowering
                  students with a diverse array of learning opportunities. With
                  Hapus, every click opens a door to knowledge, making education
                  accessible, convenient, and tailored to the needs of students
                  across India. We curate and deliver high-quality online
                  courses spanning various subjects and skills, catered to
                  learners of all ages and backgrounds. Our platform, hapus.app,
                  is designed to be intuitive, user-friendly, and supportive,
                  fostering an environment where knowledge is shared and
                  curiosity is celebrated. We are committed to removing
                  educational barriers and providing a learning experience
                  that’s both effective and inspiring.
                </p>
              </div>
            </div>
          </div>
        </div>

       
      </div>
     
      <section className="section-padding">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-lg-6">
              <h2 className="section-title mb-30">OUR VISION </h2>

              <p>
                {" "}
                We envision a future where quality education transcends physical
                boundaries, becoming accessible and convenient for every student
                in India. Hapus aims to unlock the full potential of online
                learning, ensuring that every learner has the opportunity to
                pursue their educational aspirations without constraints. We
                aspire to be the catalyst in the educational domain, where our
                platform becomes the bridge that leads to a world of knowledge,
                growth, and endless possibilities.
              </p>
              <ul className="list-unstyled">{/* List items */}</ul>
              {/* ... */}
            </div>
            <div className="col-lg-5 mt-5 mt-lg-0 text-center">
              {/* Placeholder for the image */}
              <div className="img-placeholder"></div>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-gray overflow-hidden">
        <div className="container">
          <div className="no-gutters d-block d-lg-flex align-items-center justify-content-end">
            <div className="col-lg-10 col-12 mb-5 mb-lg-0 text-center">
              {/* Placeholder for image, replaced with a colored div */}
              <div className="image-placeholder"></div>
            </div>
            <div className="col-lg-6">
              <div className="pl-lg-5 pb-5 mb-5 mb-lg-0 pb-lg-0">
                <h2 className="section-title mb-30">
                  OUR MISSION <br />{" "}
                </h2>
                <p className="mb-4">
                  {" "}
                  At Hapus, we are committed to fostering a dynamic and
                  comprehensive e-learning ecosystem that seamlessly connects
                  educational institutes and learners across India. Our mission
                  is to build an intuitive marketplace that not only broadens
                  the reach for institutes but also empowers students with an
                  extensive array of courses tailored to their learning needs.
                  Our platform is meticulously crafted to simplify the course
                  offering process for institutes and to streamline the course
                  discovery and enrollment experience for students. We strive to
                  be the quintessential one-stop e-learning destination,
                  revolutionizing the educational framework in India.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Instructors Carousel */}
      {/* ... */}

      {/* Students Says Section */}
      {/* ... */}
    </div>
  );
};

export default AboutUs;
