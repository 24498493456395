import React, { useState, useEffect,useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getCoursesByInstitute, uploadVideo } from "./InstituteApi";
import { Container, Form, Button, Modal } from "react-bootstrap";

const AddLecture = ({ setLecture }) => {
    const params = useParams();
    const navigate = useNavigate();
    const videoRef = useRef(null); // Create a ref for the video element
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    const { userId, token } = userInfo || {};
  
    const [values, setValues] = useState({
      title: "",
      description: "",
      vidDuration: "",
      error: "",
      success: "",
      createdVideo: "",
      loading: false,
      formData: new FormData(), // Initialize formData here
    });

  const { title, description, vidDuration, error,success, createdVideo, loading, formData } = values;

  const handleChange = (name) => (event) => {
    const value = name === "video" ? event.target.files[0] : event.target.value;
    formData.set(name, value);
    setValues({ ...values, [name]: value });

    if (name === "video" && value) {
        const videoFile = URL.createObjectURL(value);
           videoRef.current.src = videoFile;  // Set the source for the video element
            videoRef.current.load();  // Load the video file
        }
    };

    const onVideoLoadedMetadata = () => {
        const duration = videoRef.current.duration;
        const formattedDuration = formatDuration(duration);
        formData.set("vidDuration", formattedDuration);  // Set video duration in formData
        setValues({ ...values, vidDuration: formattedDuration });
    };


const calculateVideoDuration = (file) => {
    const reader = new FileReader();
    reader.onload = function(e) {
      const aud = new Audio(e.target.result);
      aud.onloadedmetadata = function() {
        const duration = formatDuration(aud.duration);
        formData.set("vidDuration", duration); // Set video duration in formData
        setValues({ ...values, vidDuration: duration });
      };
    };
    reader.readAsDataURL(file);
  };

  const formatDuration = (duration) => {
    const h = Math.floor(duration / 3600);
    const m = Math.floor(duration % 3600 / 60);
    const s = Math.floor(duration % 3600 % 60);
    return [h, m, s].map(v => v < 10 ? "0" + v : v).join(":");
  };

  const clickSubmit = async (event) => {
    event.preventDefault();
    setValues({ ...values, loading: true });

    // No need to set formData again here, it's already updated in handleChange
    const data = await uploadVideo(userId, token, formData, params.courseId);
    if (data.error) {
        const errorMessage = typeof data.error === 'object' ? JSON.stringify(data.error) : data.error;
        setValues({ ...values, error: errorMessage, loading: false });
    }  else {
      setValues({
        ...values,
        title: "",
        description: "",
        loading: false,
        success: `${data.name} has been created successfully`,
        createdVideo: data.name,
      });
      setLecture(false);
            navigate('/institute-dashboard'); 
    }
  };

  const closeModal = () => {
    setLecture(false);
    navigate('/institute-dashboard');
  };

  const showFeedback = () => {
    if (error) {
      return <div className="alert alert-danger">{error}</div>;
    }
    if (success) {
      return <div className="alert alert-success">{success}</div>;
    }
  };

  return (
    <Modal show={true} onHide={closeModal} centered>
      <Modal.Header closeButton>
        <Modal.Title>Add Lecture</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={clickSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Title</Form.Label>
            <Form.Control type="text" value={title} onChange={handleChange("title")} required />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Description</Form.Label>
            <Form.Control as="textarea" rows={3} value={description} onChange={handleChange("description")} required />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Video File</Form.Label>
            <Form.Control type="file" name="video" accept="video/*" onChange={handleChange("video")} required />
          </Form.Group>

          {loading && <div className="alert alert-info">Uploading...</div>}
          {showFeedback()} {/* Call showFeedback function here */}
          {createdVideo && showFeedback(`${createdVideo} is created`, "info")}

          {/* Invisible video element for loading and reading video metadata */}
          <video ref={videoRef} onLoadedMetadata={onVideoLoadedMetadata} style={{ display: "none" }} />

          <Button variant="primary" type="submit">Submit</Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default AddLecture;
