import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Layout from '../components/Layout';
import { Table } from 'react-bootstrap';
import { Trash, PencilSquare, ArrowReturnLeft } from 'react-bootstrap-icons';
import { createCategory, getCategories, updateCategory, deleteCategory } from './AdminApi';

const Category = () => {
  const [name, setName] = useState('');
  const [feedback, setFeedback] = useState({ message: '', type: '' });
  const [categories, setCategories] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [currentCategoryId, setCurrentCategoryId] = useState(null);
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const token = localStorage.getItem('userToken');

  useEffect(() => {
    loadCategories();
  }, []);

  const loadCategories = async () => {
    try {
      const data = await getCategories();
      if (!data.error) {
        setCategories(data);
      } else {
        setFeedback({ message: 'Failed to load categories', type: 'error' });
      }
    } catch (error) {
      setFeedback({ message: error.message || 'Failed to load categories', type: 'error' });
    }
  };

  const handleChange = e => setName(e.target.value);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFeedback({ message: '', type: '' });

    const action = editMode ? updateCategory(currentCategoryId, userInfo.userId, token, { name }) : createCategory(userInfo.userId, token, { name });
    
    try {
      const data = await action;
      if (data.error) {
        setFeedback({ message: data.error, type: 'error' });
      } else {
        setFeedback({ message: `Category ${editMode ? 'updated' : 'created'} successfully.`, type: 'success' });
        setEditMode(false);
        setName('');
        setCurrentCategoryId(null);
        loadCategories();
      }
    } catch (error) {
      setFeedback({ message: 'Operation failed', type: 'error' });
    }
  };

  const handleEdit = (categoryId, categoryName) => {
    setEditMode(true);
    setCurrentCategoryId(categoryId);
    setName(categoryName);
  };

  const handleDelete = async (categoryId) => {
    try {
      await deleteCategory(categoryId, userInfo.userId, token);
      loadCategories();
      setFeedback({ message: 'Category deleted successfully', type: 'success' });
    } catch (error) {
      setFeedback({ message: 'Failed to delete category', type: 'error' });
    }
  };

  const renderFeedback = () => feedback.message && (
    <div className={`alert ${feedback.type === 'error' ? 'alert-danger' : 'alert-success'}`} role="alert">
      {feedback.message}
    </div>
  );

  const CategoryForm = () => (
    <form onSubmit={handleSubmit}>
      <div className="form-group">
        <input
          type="text"
          className="form-control"
          placeholder={editMode ? 'Update category name' : 'Enter category name'}
          value={name}
          onChange={handleChange}
          autoFocus
          required
        />
      </div>
      <button className="btn btn-outline-primary">{editMode ? 'Update' : 'Create'} Category</button>
    </form>
  );

  return (
    <Layout title="Manage Categories" description={`${userInfo?.name}`}>
      <div className="row">
        <div className="col-md-8 offset-md-2">
          {renderFeedback()}
          <CategoryForm />
          <Table striped bordered hover responsive className="mt-3">
            <tbody>
              {categories.map((c, i) => (
                <tr key={i}>
                  <td>{i + 1}</td>
                  <td>{c.name}</td>
                  <td><button onClick={() => handleEdit(c._id, c.name)}><PencilSquare /></button></td>
                  <td><button onClick={() => handleDelete(c._id)}><Trash /></button></td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Link to="/admin-dashboard" className="btn btn-danger mt-3">
            <ArrowReturnLeft /> Back to Dashboard
          </Link>
        </div>
      </div>
    </Layout>
  );
};

export default Category;








// import React, { useState, useEffect, useContext } from 'react';
// import { Link } from 'react-router-dom';
// import Layout from '../components/Layout';
// import { UserContext } from '../context/UserContext';
// import { Table } from 'react-bootstrap';
// import { Trash, PencilSquare, ArrowReturnLeft } from 'react-bootstrap-icons';
// import {
//   createCategory,
//   getCategory,
//   updateCategory,
//   deleteCategory,getCategories
// } from './AdminApi';



// const Category = () => {
//   const [name, setName] = useState('');
//   const [error, setError] = useState(false);
//   const [success, setSuccess] = useState(false);
//   const [categories, setCategories] = useState([]);
//   const [editMode, setEditMode] = useState(false);
//   const [currentCategoryId, setCurrentCategoryId] = useState(null);
//   const userInfo = JSON.parse(localStorage.getItem('userInfo'));
//   const token = localStorage.getItem('userToken');

//   useEffect(() => {
//     loadCategories();
// }, []);

// const loadCategories = async () => {
//     try {
//         const data = await getCategories();
//         if (!data.error) {
//             setCategories(data);
//         }
//     } catch (error) {
//         setError('Failed to load categories');
//     }
// };


//     const handleEdit = (categoryId, categoryName) => {
//       setEditMode(true);
//       setCurrentCategoryId(categoryId);
//       setName(categoryName);
//   };

//   const handleUpdate = async (e) => {
//     e.preventDefault();
//     try {
//         // Ensure correct order and values for arguments
//         await updateCategory(currentCategoryId, userInfo.userId, token, name);
//         setEditMode(false);
//         setName('');
//         setCurrentCategoryId(null);
//         loadCategories();
//         setSuccess('Category updated successfully');
//     } catch (error) {
//         setError('Failed to update category');
//     }
// };



//   const handleDelete = async (categoryId) => {
//     try {
//         await deleteCategory(categoryId, userInfo.userId, token);
//         loadCategories();
//     } catch (error) {
//         setError('Failed to delete category');
//     }
// };

//   const handleChange = e => {
//     setError('');
//     setName(e.target.value);
//   };

//   const clickSubmit = e => {
//     e.preventDefault();
//     setError('');
//     setSuccess(false);
//     // Check if user data is loaded and user ID is available
//   if (!userInfo || !userInfo.userId) {
//     console.error('User ID is undefined');
//     setError('Cannot create category: User ID is undefined.');
//     return;
//   }
            
//     createCategory(userInfo.userId, token, { name }).then(data => {
//         if (!userInfo || !userInfo.userId) {
//             console.error('User ID is undefined');
//             return;
//           }
//       if (data.error) {
//         setError(data.error);
//       } else {
//         setError('');
//         setSuccess(true);
//         loadCategories();
//       }
//     });
//   };

//   const showError = () => (
//     error ? <div className="alert alert-danger" role="alert">{error}</div> : null
//   );

//   const showSuccess = () => (
//     success ? <h3 className="text-success">{name} created</h3> : null
//   );

//   const newCategoryForm = () => (
//     <form onSubmit={clickSubmit} className="mb-3">
//       <div className="form-group">
//          <input
//           type="text"
//           placeholder='Enter category name'
//           className="form-control"
//           onChange={handleChange}
//           value={name}
//           autoFocus
//           required
//         />
//       </div>
//       <button className="btn btn-outline-primary m-2">Create Category</button>
//     </form>
//   );



//   const goBack = () => (
//     <div className="m-4">
//       <Link to="/admin-dashboard">
//         <button className="btn btn-danger">
//           <ArrowReturnLeft /> Back to Dashboard
//         </button>
//       </Link>
//     </div>
//   );

//   return (
  


//     <Layout title="Manage Categories" description={`${userInfo?.name}`}>
//             <div className="row">
//                 <div className="col-md-8 offset-md-2">
//                     {error && <div className="alert alert-danger">{error}</div>}
//                     {success && <div className="alert alert-success">{success}</div>}
//                     {editMode ? (
//                         <form onSubmit={handleUpdate}>
//                             <div className="form-group">
//                                 <label className="text-muted">Update Category</label>
//                                 <input
//                                     type="text"
//                                     className="form-control"
//                                     onChange={(e) => setName(e.target.value)}
//                                     value={name}
//                                     autoFocus
//                                 />
//                             </div>
//                             <button className="btn btn-outline-primary">Update Category</button>
//                         </form>
//                     ) : (
//                       <form onSubmit={clickSubmit} className="mb-3">
//                       <div className="form-group">
//                          <input
//                           type="text"
//                           placeholder='Enter category name'
//                           className="form-control"
//                           onChange={handleChange}
//                           value={name}
//                           autoFocus
//                           required
//                         />
//                       </div>
//                       <button className="btn btn-outline-primary m-2">Create Category</button>
//                     </form>
//                     )}

//                     <Table striped bordered hover responsive className="text-center primary">
//                         {/* Table header */}
//                         <tbody>
//                             {categories.map((c, i) => (
//                                 <tr key={i}>
//                                     <td>{i + 1}</td>
//                                     <td>{c.name}</td>
//                                     <td>
//                                         <button onClick={() => handleEdit(c._id, c.name)}>
//                                             <PencilSquare />
//                                         </button>
//                                     </td>
//                                     <td>
//                                         <button onClick={() => handleDelete(c._id)}>
//                                             <Trash />
//                                         </button>
//                                     </td>
//                                 </tr>
//                             ))}
//                         </tbody>
//                     </Table>
//                     {goBack()}
//                     {/* Go back button */}
//                 </div>
//             </div>
//         </Layout>
//   );
// };

// export default Category;
