// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import HomePage from './components/HomePage';
import { UserProvider } from './context/UserContext';
import SharedLogin from './auth/SharedLogin';
import AdminLogin from './auth/AdminLogin';
import AdminRoute from './auth/AdminRoute';
import StudentRoute from './auth/StudentRoute';
import InstituteRoute from './auth/InstituteRoute';
import AdminDashboard from './admin/AdminDashboard';
import StudentDashboard from './student/StudentDashboard';
import InstituteDashboard from './Institute/InstituteDashboard';
import InstituteSignup from './Institute/Signup/InstituteSignup';
import 'bootstrap/dist/css/bootstrap.min.css';
import Category from './admin/Category';
import CreateCourse from './Institute/CreateCourse';
import UpdateCourse from "./Institute/UpdateCourse"
import StudentSignup from './student/StudentSignup';
import CourseDetails from './components/CourseDetails';
import Blogs from './components/Blogs';
import AboutUs from './pages/AboutUs';
import ContactUs from "./pages/ContactUs";
import NoRoute404 from './components/NoRoute404';

// Import other components as needed

const App = () => {
  return (
    
    <Router>
      <UserProvider>
    <Header />
      <Routes>

        {/* PUBLIC ROUTES */}
        <Route path="/" element={<HomePage />} />
        <Route path="/login" element={<SharedLogin />} />
        <Route path="/institute-signup" element={<InstituteSignup />} />
        <Route path='/student-signup' element={<StudentSignup />} />
        <Route path='/coursedetails/:courseId' element={<CourseDetails />} />
        <Route path='/blog-list' element={<Blogs />} />
        <Route path='/aboutus' element={<AboutUs />} />
        <Route path='/contactus' element={<ContactUs />} />
        

        <Route path="/admin/login" element={<AdminLogin />} />
        <Route path="/institute-signup" element={<InstituteSignup />} />
        <Route path="/admin-dashboard" element={<AdminRoute component={AdminDashboard} />} />
        <Route path="/admin-category" element={<AdminRoute component={Category} />} />
            <Route path="/student-dashboard" element={<StudentRoute component={StudentDashboard} />} />
            <Route path="/institute-dashboard" element={<InstituteRoute component={InstituteDashboard} />} />
            <Route path="/create-course" element={<InstituteRoute component={CreateCourse} />} />
            <Route path="/update-course/:courseId" element={<InstituteRoute component={UpdateCourse} />} />
     
     
            <Route path="*" element={<NoRoute404 />} />
      </Routes>

      <Footer />
      </UserProvider>
    </Router>
   
  );
};

export default App;
