import React, { useState, useEffect } from "react";
import Layout from "../components/Layout";
import { API } from "../config";
import { Link, useParams } from "react-router-dom";
import ReactStars from "react-rating-stars-component";
import { Helmet } from "react-helmet";
import axios from "axios";
import ReactPlayer from 'react-player';


const CourseDetails = () => {
    const [course, setCourse] = useState({});
   
    const [error, setError] = useState(false);
   
    const [lockVideo, setLockVideo] = useState(false);
    const [section, setSection] = useState(true);
    const [avgRating, setAvgRating] = useState(0);
    const [currentVideoUrl, setCurrentVideoUrl] = useState("");
    const userInfo = JSON.parse(localStorage.getItem('userInfo')) || {};
    const { userId, token } = userInfo;
    const params = useParams();
    const courseId = params.courseId;
  
  

  useEffect(() => {
    loadSingleCourse();
  }, []);

  const loadSingleCourse = async () => {
    try {
      const response = await axios.get(`${API}/course/${params.courseId}`);
      setCourse(response.data);
      // Optionally set the first video URL as the current video
      if (response.data.lectures && response.data.lectures.length > 0) {
        setCurrentVideoUrl(response.data.lectures[0].videoUrl);
      }
    } catch (error) {
      console.log("Error loading course:", error);
      setError(true);
    }
  };
  

  const handleVideoSelect = (videoUrl) => {
    setCurrentVideoUrl(videoUrl);
};
 

    
    return (
        <div>
        <Helmet>
          <title>{course.title}</title>
          <meta name="description" content={course.description} />
        </Helmet>
        {/* start of page-header */}
        <section className="py-30 bg-gray">
          <div className="container">
            <div className="row">
             
              <div className="col-12">
                <h2 className="section-title font-weight-bold mb-20">
                  Course Details
                </h2>
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb bg-transparent p-0 font-weight-600 mb-0">
                    <li className="breadcrumb-item active" aria-current="page">
                      <Link className="text-primary" to="/">
                        Home
                      </Link>
                    </li>
                    <li className="breadcrumb-item">Course Details</li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </section>
        {/* end of page-header */}
  
        {/* start of courses-details */}
        <section className="section">
          <div className="container">
            <div className="row">
              <div className="col-lg-8">

              {currentVideoUrl && (
                                <div className="video-player">
                                    <ReactPlayer url={currentVideoUrl} controls={true} />
                                </div>
                            )}
                <h2 className="text-secondary font-weight-bold mb-4">
                  {course.title}
                </h2>
                <div className="mb-5">
                  <Link
                    to=""
                    className="d-block has-overlay tansform-none rounded-0"
                  >
                    <img
                      className="img-fluid rounded "
                      src={course.photoUrl}
                      alt="thumbnail"
                    />
                  </Link>
                </div>
  
                <ul
                  className="nav nav-pills mb-4 bg-gray tab-nav nav-justified"
                  id="pills-tab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <Link
                      className={
                        section === true ? "nav-link active" : "nav-link"
                      }
                      id="pills-overview-tab"
                      data-toggle="pill"
                      to="#pills-overview"
                      role="tab"
                      aria-controls="pills-overview"
                      aria-selected="true"
                      onClick={() => setSection(true)}
                    >
                      Overview
                    </Link>
                  </li>
                 
                 
                 
                </ul>
                <div className="tab-content" id="pills-tabContent">
                  {section === true && (
                    <div
                      className=""
                      role="tabpanel"
                      aria-labelledby="pills-overview-tab"
                    >
                      <p className="mb-3">{course.description}</p>
                      <div className="media has-outline-primary align-items-center mt-40">
                        <img
                          className="rounded-circle"
                          src="/images/user-07.jpg"
                          alt="user-07"
                        />
                        <div className="ml-3">
                          <h5 className="text-blue font-weight-600 mb-1">
                            Pianoforall
                          </h5>
                          <p>Released: 2/5/2020</p>
                        </div>
                      </div>
                    </div>
                  )}
  
                  
                </div>
  
               
              </div>
              <div className="col-lg-4 mt-5 mt-lg-0">
                <div className="widget">
                  <div className="d-flex flex-row align-items-center mb-20 mt-3">
                    {/* <h2 className="text-blue font-weight-600">
                      &#x20B9; {course.price}{" "}
                    </h2> */}
  
                  
                      <button
                        
                        className="btn btn-sm btn-primary"
                      >
                        {/* Buy Now at &#x20B9; {course.price} */}
                        Buy Now &#x20B9; {course.price}
                        {/* &#x20B9; {course.price} */}
                      </button>
                  
                    <div className="rating text-primary ml-4 rating-font">
                      {avgRating > 0 && (
                        <div  className="d-flex flex-row align-items-center ms-2">
                      
                        <ReactStars
                          name="rating"
                          count={5}
                          value={avgRating}
                          size={24}
                          edit={false}
                          activeColor="#f16001"
                          isHalf={true}
                          emptyIcon={<i className="far fa-star"></i>}
                          halfIcon={<i className="fa fa-star-half-alt"></i>}
                          fullIcon={<i className="fa fa-star"></i>}
                        />
                         </div>
                      )}
                    </div>
                  </div>
                </div>
  
                <div className="widget shadow mt-8">
                <h4 className="widget-title">Course Curriculum</h4>
                <ul className="courser-curriculum widget-list list-unstyled">
                  {course.lectures?.map((v, i) => (
                    <li key={i}>
                      <Link to={`/video/${v._id}/${params.courseId}`}>
                        <i className="bi bi-play-circle-fill"> </i>{" "}
                        {v.title}
                        <span className="f-right">{v.vidDuration}</span>
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              </div>
            </div>
          </div>
        </section>
  
        {/* end of courses-details */}
      </div>
    );
  
  };
  
  export default CourseDetails;