import React from 'react';

const Blogs = () => {
    return (
        <div className="under-development-container">
            <h1>Blogs</h1>
            <p className="under-development-message">This page is currently under development. Please check back later!</p>
        </div>
    );
};

export default Blogs;
