import react, { useState, useEffect } from "react";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import Layout from "../components/Layout";
import AddLecture from "./AddLecture";
import { getCourse , patchCourse, getCategories} from "./InstituteApi"

const UpdateCourse = ({match}) => {

    const params = useParams();
  const navigate = useNavigate();

  // Retrieve user information from localStorage
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const { userId, token } = userInfo || {};



    const [values, setValues] = useState({
        title: "",
        description: "",
        shortDescription: "",
        price: "",
        category: "",
        loading: false,
        error: "",
        createdCourse: "",
        formData: new FormData(),
        lectures: [],
        photoUrl: "",
      });


  const [lecture,setLecture] = useState(false)
  const [categories, setCategories] = useState([]);
  const { title, description, shortDescription, price, category, loading, error, createdCourse, formData, lectures, photoUrl } = values;

  const init = () => {
    console.log("Fetching course with ID:", params.courseId);
    getCourse(params.courseId).then((data) => {
      if (data.error) {
        setValues({ ...values, error: data.error });
      } else {
        setValues({
          ...values,
          title: data.title,
          description: data.description,
          shortDescription: data.shortDescription,
          price: data.price,
          category: data.category._id,
          lectures: data.lectures,
          photoUrl: data.photoUrl,
          formData: new FormData(),
        });
        initCategories();
      }
    });
  };

  const initCategories = () => {
    getCategories().then(data => {
      if (!data.error) {
        setCategories(data);
      }
    });
  };


  useEffect(() => {
    init();
  }, [params.courseId]);

  const handleChange = (name) => (event) => {
    const value = name === "photo" ? event.target.files[0] : event.target.value;
    formData.set(name, value);
    setValues({ ...values, [name]: value });
  };

  const clickSubmit = (event) => {
    event.preventDefault();
    setValues({ ...values, error: "", loading: true });

    patchCourse(params.courseId, userId, token, formData).then((data) => {
      if (data.error) {
        setValues({ ...values, error: data.error, loading: false });
      } else {
        setValues({
          ...values,
          loading: false,
          error: "",
          createdCourse: data.title,
        });
        navigate('/institute-dashboard');
      }
    });
  };


const UpdateCourseForm = () => (
    <form className="mb-3" onSubmit={clickSubmit}>
         <div className="form-group">
              <img className="img-fluid" src={photoUrl} alt="hapus"/>
            </div>
        <div className="form-group">
                <label className="text-muted">Title</label>
                <input maxLength="120" onChange={handleChange('title')} type="text" className="form-control" value={title} />
            </div>

            <div className="form-group">
                <label className="text-muted">Short Description</label>
                <input maxLength="40" onChange={handleChange('shortDescription')} className="form-control" value={shortDescription} />
            </div>

            <div className="form-group">
                <label className="text-muted">Description</label>
                <textarea maxLength="1000" onChange={handleChange('description')} className="form-control" value={description} />
            </div>

            <div className="form-group">
                <label className="text-muted">Price</label>
                <input onChange={handleChange('price')} type="number" className="form-control" value={price} />
            </div>

            <div className="form-group">
                <label className="text-muted">Category</label>
                <select onChange={handleChange('category')} className="form-control">
                   
                    {categories &&
                        categories.map((c, i) => (
                            <option key={i} value={c._id}>
                                {c.name}
                            </option>
                        ))}
                </select>
            </div>


            <button type="submit" className="btn btn-outline-primary">Update Course</button>
        </form>
    );

// Functionality for Show Success and Error
const showFeedback = (message, type) => (
    <div className={`alert alert-${type}`}>{message}</div>
  );


const showLoading = () => 
loading && (
  <div className="alert alert-success"><h2>Loading...</h2></div>
)



  return (
    <Layout title="Update Course" description={`G'day ${userInfo.name}, ready to update the course?`}>
    <div className="row">
      <div className="col-md-8 offset-md-2">
        {error && showFeedback(error, "danger")}
        {createdCourse && showFeedback(`"${createdCourse}" is updated!`, "info")}
        {loading && showFeedback("Loading...", "success")}
        {UpdateCourseForm()}

                {lecture === true && <AddLecture setLecture={setLecture} init={init}/>}
                                
                <div className="col-md-4 text-right">
                  <button onClick={() => setLecture(true)} className="btn btn-outline-primary width-100">Add Lecture</button>
                  <div className="widget shadow" style={{marginTop : "8%"}}>
                <h4 className="widget-title">Course Curriculum</h4>
                <ul className="courser-curriculum widget-list list-unstyled">
                  {lectures &&
                    lectures &&
                    lectures.map((v, i) => (
                      <li key={i} value={v._id}>
                        <Link to={`/video/${v._id}/${params.courseId}`}>
                          <i className="bi bi-play-circle-fill"> </i> {v.title}
                          <span className="f-right">{v.vidDuration}</span>
                        </Link>
                      </li>
                    ))}
                </ul>
              </div>

                </div>
                </div>
      </div>
    </Layout>
  );
};

export default UpdateCourse;
