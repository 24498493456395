import React from "react";
import { Container } from "react-bootstrap";

const Layout = ({
  title = "Title",
  description = "Description",
  parentComp,
  children,
  className,
}) => (
  <div className="layout-wrapper">
    <div className="header p-3 mb-2 text-blue">
      <Container>
        <div className="row">
          <h1 className="col-8 nav-header-1">{title}</h1>
          {parentComp === "instDash" && <p className="col-4">{description}</p>}
        </div>
      </Container>
    </div>
    <div className="main-content">
      
      <Container>
        <div className={className}>{children}</div>
      </Container>
    </div>
  </div>
);

export default Layout;
