// src/context/UserContext.js
import React, { createContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import apiService from '../services/apiService';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [authError, setAuthError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        checkUserLoggedIn().then(loggedInUser => {
            setUser(loggedInUser);
        });
    }, []);

    const login = async (credentials, type) => {
        try {
            let response;
            switch (type) {
                case 'institute':
                    response = await apiService.loginInstitute(credentials);
                    break;
                case 'student':
                    response = await apiService.loginUser(credentials);
                    break;
                case 'admin':
                    response = await apiService.loginAdmin(credentials);
                    break;
                default:
                    throw new Error('Invalid user type');
            }
            setUser({ ...response.data, userType: type });
            localStorage.setItem('userToken', response.data.token); // Store the token
            localStorage.setItem('userInfo', JSON.stringify({ ...response.data, userType: type })); // Store user info
            setAuthError(null);

            // Redirect based on userType
            switch (type) {
                case 'admin':
                    navigate('/admin-dashboard');
                    break;
                case 'student':
                    navigate('/student-dashboard');
                    break;
                case 'institute':
                    navigate('/institute-dashboard');
                    break;
                default:
                    // Handle unexpected userType
            }
        } catch (error) {
            setAuthError(error.response?.data?.message || 'Login failed');
        }
    };

    const logout = () => {
        setUser(null);
    localStorage.removeItem('userToken'); // Clear the token
    localStorage.removeItem('userInfo'); // Clear user info
    };

    return (
        <UserContext.Provider value={{ user, authError, login, logout }}>
            {children}
        </UserContext.Provider>
    );
};


// In a utility file or within the UserContext file
// Utility function to check if the user is logged in
export const checkUserLoggedIn = () => {
    return new Promise(resolve => {
        const userInfo = localStorage.getItem('userInfo');
        if (userInfo) {
            resolve(JSON.parse(userInfo));
        } else {
            resolve(null);
        }
    });
};
  