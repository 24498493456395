import React from 'react';
import { useNavigate } from 'react-router-dom';

const NoRoute404 = () => {
    const navigate = useNavigate();

    return (
        <div className="no-route-container">
            <h1>404</h1>
            <p>Oops! The page you're looking for doesn't exist.</p>
            <button onClick={() => navigate(-1)} className="btn-go-back">Go Back</button>
            <button onClick={() => navigate('/')} className="btn-home">Home Page</button>
        </div>
    );
};

export default NoRoute404;
