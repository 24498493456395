import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row, Card, Table, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faFileAlt,
  faChartLine,
  faUsers,
  faCog,
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import Layout from "../components/Layout";
import { getStats, getCoursesByInstitute } from "./InstituteApi";

const InstituteDashboard = () => {
  const [courseInfo, setCourseInfo] = useState([]);
  const [generalStats, setGeneralStats] = useState([]);
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const { userId, token } = userInfo || {};

  useEffect(() => {
    if (userId && token) {
      getCoursesByInstitute(userId, token).then((data) => {
        if (!data.error) setCourseInfo(data);
        else console.log(data.error);
      });

      getStats(userId, token)
        .then((data) => {
          if (data) setGeneralStats(data);
          else console.log("No stats available");
        })
        .catch((error) => console.error("Error fetching stats:", error));
    }
  }, [userId, token]);

  return (
    <Layout
      title="Dashboard"
      description={`Hello, ${userInfo?.name}!`}
      className="container-fluid"
    >
      <Container>
        <Row>
          <Col lg={4} md={6} sm={12} className="d-flex align-items-stretch">
            <Card className="w-100">
              <Card.Header>Institute Links</Card.Header>
              <Card.Body className="d-flex flex-column justify-content-between">
  <ul className="list-unstyled">
    <li className="d-block mb-3">
      <Link to="/create-course" className="d-block">
        <FontAwesomeIcon icon={faFileAlt} />
        <h5 className="d-inline ml-2">Create Course</h5>
      </Link>
    </li>
    <li className="d-block mb-3">
      <Link to={`/institute/update/${userId}`} className="d-block">
        <FontAwesomeIcon icon={faEdit} />
        <h5 className="d-inline ml-2">Update Profile</h5>
      </Link>
    </li>
    <li className="d-block mb-3">
      <Link to={`/institute/review`} className="d-block">
        <FontAwesomeIcon icon={faUsers} />
        <h5 className="d-inline ml-2">Review's Got</h5>
      </Link>
    </li>
    <li className="d-block mb-3">
      <Link to={`/list/quiz`} className="d-block">
        <FontAwesomeIcon icon={faChartLine} />
        <h5 className="d-inline ml-2">Quiz</h5>
      </Link>
    </li>
    <li className="d-block">
      <Link to={`/blogList`} className="d-block">
        <FontAwesomeIcon icon={faCog} />
        <h5 className="d-inline ml-2">Blog</h5>
      </Link>
    </li>
  </ul>
</Card.Body>


            </Card>
          </Col>
          <Col sm={6}>
            <Card>
              <Card.Header>General Stats</Card.Header>
              <Card.Body>
                {generalStats.monthlyTotal ? (
                  <ul>
                    <li>
                      <FontAwesomeIcon icon={faCheckCircle} className="text-success mr-2" />
                      Monthly Total: {generalStats.monthlyTotal}
                    </li>
                    <li>
                      <FontAwesomeIcon icon={faCheckCircle} className="text-success mr-2" />
                      Total Earning: {generalStats.sumTotal}
                    </li>
                    <li>
                      <FontAwesomeIcon icon={faCheckCircle} className="text-success mr-2" />
                      No. user subscribed: {generalStats.noUser}
                    </li>
                  </ul>
                ) : (
                  <div className="text-center">
                    <FontAwesomeIcon icon={faTimesCircle} className="text-danger" />
                    <h5 className="text-danger">No stats available</h5>
                  </div>
                )}
              </Card.Body>
            </Card>
            <Table
              striped
              bordered
              hover
              className="mt-4"
              responsive
              size="sm"
            >
              <thead>
                <tr>
                  <th>#</th>
                  <th>Manage Course</th>
                </tr>
              </thead>
              <tbody>
                {courseInfo.map((c, i) => (
                  <tr key={c._id}>
                    <td>{i + 1}</td>
                    <td>
                      <Link to={`/update-course/${c._id}`}>
                        {c.title} <FontAwesomeIcon icon={faEdit} />
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default InstituteDashboard;


// import React, {useState,useEffect} from 'react';
// import {Link} from "react-router-dom"
// import {Col, Container, Row} from "react-bootstrap";
// import Layout from "../components/Layout"
// import { getStats, getCoursesByInstitute } from './InstituteApi';

// const InstituteDashboard = () => {
//     const [courseInfo, setCourseInfo] = useState([]);
//     const [generalStats, setGeneralStats] = useState([]);

//   // Retrieve user information from localStorage
//   const userInfo = JSON.parse(localStorage.getItem('userInfo'));
//   const { userId, token } = userInfo || {}; // Destructure userId and token

//   useEffect(() => {
//     if (userId && token) {
//         getCoursesByInstitute(userId, token).then(data => {
//             if (!data.error) {
//                 setCourseInfo(data);
//             } else {
//                 console.log(data.error);
//             }
//         });

//         getStats(userId, token).then(data => {
//             if (data) {
//                 setGeneralStats(data);
//             } else {
//                 console.log('No stats available');
//             }
//         }).catch(error => {
//             console.error('Error fetching stats:', error);
//         });
//     }
// }, [userId, token]); // Only rerun the effect if userId or token changes

//   const init = (userId, token) => {
//       getCoursesByInstitute(userId, token).then(data => {
//           if (!data.error) {
//               setCourseInfo(data);
//           } else {
//               console.log(data.error);
//           }
//       });
//   };

//   const stats = (userId, token) => {
//     getStats(userId, token).then(data => {
//         // Assuming the data returned doesn't have an 'error' field when successful
//         if (data) {
//             setGeneralStats(data);
//         }else {
//             console.log('No stats available');
//         }
//     }).catch(error => {
//         // Handle any errors that occur during the API call
//         console.error('Error fetching stats:', error);
//     });
// };

//     const instLinks = () => {
//       return (
//         <div className="card">
//           <h4 className="card-header bg-primary text-white">Institute Links</h4>
//           <ul className="list-group">
//             <li className="list-group-item">
//               <Link className="nav-link theme-color-blue" to="/create-course">
//                 <i className="bi bi-file-earmark-plus"></i> Create Course
//               </Link>
//               <Link
//                 className="nav-link theme-color-blue"
//                 to={`/institute/update/${userId}`}
//               >
//                 <i className="bi bi-pencil-square"></i> Update Profile
//               </Link>
//               <Link
//                 className="nav-link theme-color-blue"
//                 to={`/institute/review`}
//               >
//                 <i className="bi bi-pencil-square"></i> Review's Got
//               </Link>
//               <Link
//                 className="nav-link theme-color-blue"
//                 to={`/list/quiz`}
//               >
//                 <i className="bi bi-pencil-square"></i> Quiz
//               </Link>
//               <Link
//                 className="nav-link theme-color-blue"
//                 to={`/blogList`}
//               >
//                 <i className="bi bi-pencil-square"></i> Blog
//               </Link>
//             </li>
//           </ul>
//         </div>
//       );
//     };

//     const instInfo = () => {
//         return (
//             <div className="card mb-5">
//                 <h3 className="card-header bg-success text-white">General Stats</h3>
//                 {Object.keys(generalStats).length ? (
//                     <ul className="list-group">
//                         <li className="list-group-item">Monthly Total: {generalStats.monthlyTotal}</li>
//                         <li className="list-group-item">Total Earning: {generalStats.sumTotal}</li>
//                         <li className="list-group-item">No. user subscribed: {generalStats.noUser}</li>
//                     </ul>
//                 ) : (
//                     <div className="card-body">No stats available.</div>
//                 )}
//             </div>
//         );
//     };

//     const coursesInfo = () => {
//         return (
//             <table className="table table-striped table-hover">
//                 <thead>
//                     <tr className="bg-head">
//                         <th scope="col"></th>
//                         <th scope="col">Manage Course</th>
//                     </tr>
//                 </thead>
//                 <tbody>
//                     {courseInfo.length ? (
//                         courseInfo.map((c, i) => (
//                             <tr key={i}>
//                                 <td>{i + 1}</td>
//                                 <td>
//                                     <Link className="nav-link theme-color-blue" to={`/update-course/${c._id}`}>
//                                         {c.title}
//                                         <i className="fas fa-edit f-right"></i>
//                                     </Link>
//                                 </td>
//                             </tr>
//                         ))
//                     ) : (
//                         <tr>
//                             <td colSpan="2">No courses available.</td>
//                         </tr>
//                     )}
//                 </tbody>
//             </table>
//         );
//     };

//     return (
//       <Layout
//         title="Dashboard"
//         description={`Hello, ${userInfo.name}!`}
//         parentComp="instDash"
//         className="container-fluid"
//       >
//         <Container>
//           <Row>
//             {/* <Col className="mb-2"  sm={4}> {instLinks()}</Col> */}
//             <div className="col-lg-4 col-md-6 col-sm-12">{instLinks()}</div>

//             <Col sm={6}>
//               {instInfo()}
//               {coursesInfo()}
//             </Col>
//           </Row>
//         </Container>
//       </Layout>
//     );
//   };

// export default InstituteDashboard;
