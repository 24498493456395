import React, {useState} from 'react'
import { API } from '../config';
import { Link } from 'react-router-dom';
import axios from 'axios';

const StudentSignup = () => {
    const [formData, setFormData] = useState({
        name: '',
        mobilenumber: '',
        email: '',
        password: '',
        confirmPassword: '',
        tnc: false,
    });
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    const handleChange = (event) => {
        const { name, value } = event.target;

        if (name === "mobilenumber") {
            const formattedValue = value.replace(/[^0-9]/g, '').substring(0, 10);
            setFormData({ ...formData, [name]: formattedValue });
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await axios.post(`${API}/student/signup`, formData);
            if (response.data.error) {
                setError(response.data.error);
                setSuccess('');
            } else {
                setSuccess(response.data.message); // Assuming the API returns a success message
                setError('');
                setFormData({
                    name: '',
                    mobilenumber: '',
                    email: '',
                    password: '',
                    confirmPassword: '',
                    tnc: false,
                });
            }
        } catch (err) {
            setError('Signup failed. Please try again.');
            setSuccess('');
            console.error('Signup Error:', err);
        }
    };

  return (

<>

<div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-md-6">
          <div className="card">
            <div className="card-body">
              <h3 className="card-title text-center">Student  Signup</h3>
              {error && <div className="alert alert-danger">{error}</div>}
                            {success && <div className="alert alert-success">{success}</div>}
              <form onSubmit={handleSubmit}>
                <div className="form-group mb-3">
                  <label htmlFor="name">Name</label>
                  <input 
                    type="text" 
                    className="form-control" 
                    id="name" 
                    name="name" 
                    value={formData.name} 
                    onChange={handleChange} 
                    required 
                  />
                </div>

                <div className="form-group mb-3">
                  <label htmlFor="email">Email</label>
                  <input 
                    type="email" 
                    className="form-control" 
                    id="email" 
                    name="email" 
                    value={formData.email} 
                    onChange={handleChange} 
                    required 
                  />
                </div>

                <div className="form-group mb-3">
    <label htmlFor="mobilenumber">Mobile</label>
    <input 
        type="tel" 
        className="form-control" 
        id="mobilenumber" 
        name="mobilenumber"  // Corrected name to match the handleChange function
        value={formData.mobilenumber} 
        onChange={handleChange} 
        required 
        maxLength={10}  // This ensures that the input does not exceed 10 characters
    />
</div>


                <div className="form-group mb-3">
                  <label htmlFor="password">Password</label>
                  <input 
                    type="password" 
                    className="form-control" 
                    id="password" 
                    name="password" 
                    value={formData.password} 
                    onChange={handleChange} 
                    required 
                  />
                </div>

                <div className="form-group mb-4">
                  <label htmlFor="confirmPassword">Confirm Password</label>
                  <input 
                    type="password" 
                    className="form-control" 
                    id="confirmPassword" 
                    name="confirmPassword" 
                    value={formData.confirmPassword} 
                    onChange={handleChange} 
                    required 
                  />
                </div>

                <div className="text-center">
                  <button type="submit" className="btn btn-primary">Sign Up</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>

  </>
  )
}

export default StudentSignup