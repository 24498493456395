// src/auth/AdminRoute.js
import React, { useContext, useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { UserContext, checkUserLoggedIn } from '../context/UserContext';

const AdminRoute = ({ component: Component }) => {
    const { user } = useContext(UserContext);

    const [isUserChecked, setIsUserChecked] = useState(false);

    useEffect(() => {
        checkUserLoggedIn().then(loggedInUser => {
            setIsUserChecked(true);
            // Optionally update user in context
            // setUser(loggedInUser);
        });
    }, []);
    if (!isUserChecked) {
        return <div>Loading...</div>; // Or a loading spinner
    }

    return user && user.userType === 'admin' ? <Component /> : <Navigate to="/admin/login" replace />;
};

export default AdminRoute;
